import { until } from "@vueuse/shared"
import { currentUser, currentUserData } from "./auth"
import trackEvent from "./track-event"
import Gleap from "gleap"

export default async function useLiveChat() {
    // analytics
    Gleap.on("open", () => {
        trackEvent("Gleap widget Opened")
    })
    Gleap.on("close", () => {
        trackEvent("Gleap widget Closed")
    })
    Gleap.on("conversation-started", (data) => {
        trackEvent(
            "Gleap user started a new conversation with optional workflow.",
            { data },
        )
    })
    Gleap.on("feedback-sent", (formData) => {
        trackEvent("Gleap did send a feedback item.", { data: formData })
    })
    Gleap.on("outbound-sent", (outboundData) => {
        trackEvent("Gleap did send survey results.", { data: outboundData })
    })
    Gleap.on("flow-started", (flow) => {
        trackEvent("The user selected a flow", { data: flow })
    })
    Gleap.on("error-while-sending", () => {
        trackEvent("Gleap error while sending")
    })
    Gleap.on("email-sent", (params) => {
        trackEvent("Gleap Email Sent", params)
    })

    // user identification
    await until(currentUser).toBeTruthy()
    const user = currentUser.value!

    let attributes: Record<string, any> = {
        name: user.name || user.email,
        email: user.email,
        userId: user.sub,
        customData: {
            retoolLink: `https://pitchlane.retool.com/apps/eb16b960-7b38-11ed-811d-43787b1402eb/Internal%20Portal/Internal%20Portal%20-%20Users%20View#user=${encodeURIComponent(
                user.sub!,
            )}`,
        },
    }
    Gleap.identify(user.sub!, attributes)
    window.__ls?.("identify", attributes)

    // user subscription data
    await until(currentUserData).toBeTruthy()
    const { planName, monthlyVideos, hasActiveSubscription } =
        currentUserData.value?.subscription ?? {}
    attributes = {
        ...attributes,
        plan: planName,
        customData: {
            ...attributes.customData,
            monthlyVideos,
            // data attribute needs to be a string
            hasActiveSubscription: hasActiveSubscription ? "true" : "false",
        },
    }
    Gleap.identify(user.sub!, attributes)

    // session replay
    window.__ls?.("getSessionURL", (url: string) => {
        attributes.session_replay = url
        window.Beacon?.("identify", attributes)
    })
}
