import amplitude from "amplitude-js"
import * as Sentry from "@sentry/browser"
import ServiceBell from "@servicebell/widget"

import api from "./api"
import { currentUser, currentUserData } from "./auth"
import { getPaddle } from "./paddle"

export default async function identify() {
    const user = currentUser.value!
    const userId = user.sub
    if (!userId) {
        console.error("no userId")
        return
    }

    const { data } = await api.get("/me")
    currentUserData.value = data

    window.analytics?.identify(userId, { ...currentUserData.value })

    const identify = new amplitude.Identify()
    identify.set("user_id", userId)
    amplitude.identify(identify)

    ServiceBell?.("identify", userId, {
        ...currentUserData.value,
        email: user.email ?? "",
        displayName: user.name ?? "",
    })

    window.Median?.identify?.(user.email)

    Sentry.setUser({
        ...currentUserData.value,
        id: userId,
    })

    // check iubenda consent before setting identity in pixels
    const { purposes = {} } = window._iub?.cs?.api?.getPreferences() ?? {}

    if (purposes?.["5"]) {
        window.gtag?.("set", "user_id", userId)
    }

    // initialize Paddle (for retain)
    getPaddle()
}
